import React from "react";
import { FaProjectDiagram, FaChartLine, FaMobileAlt } from "react-icons/fa";
import { 
  Container, Typography, Box, Grid, Card, 
  CardContent, Chip, Button 
} from "@mui/material";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import theme from './theme';

const projects = [
  {
    title: "Enterprise Data Platform",
    description: "Built a scalable data platform using Azure services for a large enterprise client.",
    icon: <FaProjectDiagram />,
    technologies: ["Azure", "Python", "Databricks", "Power BI"],
    status: "Completed"
  },
  {
    title: "Financial Analytics Dashboard", 
    description: "Developed interactive financial dashboards with real-time data integration.",
    icon: <FaChartLine />,
    technologies: ["Power BI", "SQL", "DAX", "Azure", "Snowflake"],
    status: "Completed"
  },
  {
    title: "Mobile Health App",
    description: "Created a healthcare monitoring app with wearable device integration.",
    icon: <FaMobileAlt />,
    technologies: ["Flutter", "Firebase", "Node.js"],
    status: "Completed"
  }
];

const Projects = () => {
  return (
    <Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 4,
              fontWeight: 700,
              background: theme.gradients.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
            align="center"
          >
            Industries and Projects
          </Typography>
          <Typography 
            variant="body1" 
            align="center" 
            color="text.secondary"
            sx={{ 
              mb: 8, 
              maxWidth: '600px', 
              mx: 'auto',
              fontSize: '1.1rem'
            }}
          >
            Delivering innovative solutions across various industries
          </Typography>

          {/* Industries Overview */}
          <Box sx={{ mb: 8 }}>
            <Typography 
              variant="h2"
              align="left"
              sx={{ 
                mb: 3,
                fontWeight: 700,
                background: theme.gradients.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Our Focus Industries
            </Typography>
            <Typography 
              variant="body1" 
              paragraph 
              sx={{ 
                mb: 6,
                color: 'text.secondary',
                maxWidth: '800px',
                align: 'center'
              }}
            >
              We combine cutting-edge technology with deep industry expertise to drive 
              innovation and digital transformation across key business sectors.
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 3,
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: 'primary.main',
                      bgcolor: 'background.paper',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1581091226825-a6a2a5aee158"
                    sx={{
                      width: 200,
                      height: 150,
                      objectFit: 'cover',
                      borderRadius: 2
                    }}
                  />
                  <Box>
                    <Typography 
                      variant="h5"
                      sx={{ 
                        ml: 0,
                        fontWeight: 700,
                        background: theme.gradients.primary,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontSize: '1.5rem'
                      }}
                    >
                      Industry & Manufacturing
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mt: 2,
                        color: 'text.secondary',
                        fontSize: '1.1rem',
                        lineHeight: 1.6
                      }}
                    >
                      Empowering manufacturing excellence through data-driven insights 
                      and smart applications. We help industrial clients optimize 
                      production lines, implement predictive maintenance, and achieve 
                      operational excellence through digital transformation.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 3,
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: 'primary.main',
                      bgcolor: 'background.paper',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1560520653-9e0e4c89eb11"
                    sx={{
                      width: 200,
                      height: 150,
                      objectFit: 'cover',
                      borderRadius: 2
                    }}
                  />
                  <Box>
                    <Typography 
                      variant="h5"
                      sx={{ 
                        ml: 0,
                        fontWeight: 700,
                        background: theme.gradients.primary,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontSize: '1.5rem'
                      }}
                    >
                      Financial Services
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mt: 2,
                        color: 'text.secondary',
                        fontSize: '1.1rem',
                        lineHeight: 1.6
                      }}
                    >
                      Transforming financial operations through innovative solutions. 
                      From advanced analytics for budgeting and forecasting to 
                      intelligent position management systems, we help financial 
                      institutions stay ahead in the digital age.
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: 3,
                    p: 3,
                    borderRadius: 2,
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      borderColor: 'primary.main',
                      bgcolor: 'background.paper',
                      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)'
                    }
                  }}
                >
                  <Box
                    component="img"
                    src="https://images.unsplash.com/photo-1576091160399-112ba8d25d1d"
                    sx={{
                      width: 200,
                      height: 150,
                      objectFit: 'cover',
                      borderRadius: 2
                    }}
                  />
                  <Box>
                    <Typography 
                      variant="h5"
                      sx={{ 
                        ml: 0,
                        fontWeight: 700,
                        background: theme.gradients.primary,
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                        fontSize: '1.5rem'
                      }}
                    >
                      Healthcare
                    </Typography>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        mt: 2,
                        color: 'text.secondary',
                        fontSize: '1.1rem',
                        lineHeight: 1.6
                      }}
                    >
                      Advancing healthcare through intelligent collaboration systems. 
                      We develop solutions that enhance patient care, streamline 
                      operations, and enable data-driven decision-making in 
                      healthcare organizations.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Typography 
            variant="h2" 
            sx={{ 
              mb: 4,
              fontWeight: 700,
              background: theme.gradients.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Featured Projects
          </Typography>

          <Grid container spacing={4}>
            {projects.map((project, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card
                    sx={{
                      height: '100%',
                      transition: 'all 0.3s ease',
                      border: '1px solid',
                      borderColor: 'divider',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    <CardContent sx={{ p: 4 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            p: 1.5,
                            borderRadius: 2,
                            color: 'white',
                            display: 'flex',
                            background: theme.gradients.iconGradient,
                          }}
                        >
                          {project.icon}
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            ml: 2,
                            fontWeight: 600,
                            background: theme.gradients.primary,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          }}
                        >
                          {project.title}
                        </Typography>
                      </Box>

                      <Typography 
                        color="text.secondary" 
                        sx={{ mb: 3 }}
                      >
                        {project.description}
                      </Typography>

                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 2 }}>
                        {project.technologies.map((tech, i) => (
                          <Chip
                            key={i}
                            label={tech}
                            size="small"
                            sx={{
                              backgroundColor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                            }}
                          />
                        ))}
                      </Box>

                      <Chip
                        label={project.status}
                        size="small"
                        sx={{
                          background: theme.gradients.iconGradient,
                          color: 'white',
                          fontWeight: 500
                        }}
                      />
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Projects;
