import React from "react";
import { FaLinkedin, FaGithub, FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { 
  Box, Container, Typography, Grid, IconButton, 
  Divider, Link 
} from "@mui/material";
import { motion } from "framer-motion";
import theme from "./theme";

const Footer = () => {
  const socialLinks = [
    { 
      icon: <FaLinkedin />, 
      url: "https://www.linkedin.com/in/vladislav-levitin-3ab860150/", 
      label: "LinkedIn" 
    },
    { 
      icon: <FaGithub />, 
      url: "https://github.com/vladlevitin", 
      label: "GitHub" 
    }
  ];

  const contactLinks = [
    {
      icon: <FaPhone style={{ marginRight: '8px' }} />,
      content: "(+47) 909 22 042",
      url: "tel:+4790922042"
    },
    {
      icon: <FaEnvelope style={{ marginRight: '8px' }} />,
      content: "vladislav.levitin@vlimplement.com",
      url: "ms-outlook:compose?to=vladislav.levitin@vlimplement.com"
    }
  ];

  const footerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut"
      }
    }
  };

  return (
    <Box
      component={motion.footer}
      variants={footerVariants}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      sx={{
        bgcolor: 'background.paper',
        color: 'text.primary',
        py: 6,
        mt: 'auto',
        borderTop: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                mb: 4,
                background: theme.gradients.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              VLImplement
            </Typography>
            <Typography 
              variant="body2" 
              sx={{ 
                opacity: 0.8,
                color: 'text.secondary',
                mb: 3
              }}
            >
              Org. nr: 934 134 176
            </Typography>
            <Box 
              sx={{ 
                display: 'flex', 
                alignItems: 'center',
                color: 'text.secondary',
                mb: 3,
                opacity: 0.8
              }}
            >
              <FaMapMarkerAlt style={{ marginRight: '8px' }} />
              <Typography variant="body2">
                Tollbugata 13, 0152 Oslo
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                mb: 4,
                color: 'text.primary'
              }}
            >
              Contact
            </Typography>
            {contactLinks.map((contact, index) => (
              <Link
                key={index}
                href={contact.url}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 3,
                  color: 'text.secondary',
                  textDecoration: 'none',
                  '&:hover': {
                    color: 'primary.main'
                  }
                }}
              >
                {contact.icon}
                <Typography variant="body2">
                  {contact.content}
                </Typography>
              </Link>
            ))}
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 600,
                mb: 4,
                color: 'text.primary'
              }}
            >
              Connect with us
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              {socialLinks.map((social, index) => (
                <IconButton
                  key={index}
                  component={Link}
                  href={social.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={social.label}
                  sx={{
                    color: 'text.secondary',
                    '&:hover': {
                      color: 'primary.main',
                      backgroundColor: 'rgba(0, 0, 0, 0.04)'
                    }
                  }}
                >
                  {social.icon}
                </IconButton>
              ))}
            </Box>
          </Grid>
        </Grid>

        <Divider 
          sx={{ 
            my: 4, 
            borderColor: 'divider'
          }} 
        />

        <Box 
          sx={{ 
            textAlign: 'center',
            color: 'text.secondary'
          }}
        >
          <Typography variant="body2">
            © {new Date().getFullYear()} VLImplement. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
