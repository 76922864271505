import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import theme from '../theme';

const TypewriterText = ({ text, speed = 75, useGradient = true, ...props }) => {
  const [displayText, setDisplayText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  
  useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayText(prev => {
          return text.slice(0, currentIndex);
        });
        currentIndex++;
      } else {
        clearInterval(interval);
        const blinkInterval = setInterval(() => {
          setShowCursor(prev => !prev);
        }, 400);
        return () => clearInterval(blinkInterval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);

  const gradientStyle = useGradient ? {
    background: theme.gradients.primary,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  } : {};

  return (
    <Typography {...props} sx={{ ...props.sx, ...gradientStyle }}>
      {displayText}
      <span 
        style={{ 
          opacity: showCursor ? 1 : 0,
          transition: 'opacity 0.05s ease',
          color: 'inherit',
          WebkitBackgroundClip: 'text',
          marginLeft: '2px'
        }}
      >
        _
      </span>
    </Typography>
  );
};

export default TypewriterText; 