import { createTheme } from '@mui/material/styles';
import { gradients } from './styles/gradients';

const theme = createTheme({
  palette: {
    primary: {
      main: gradients.colors.mediumBlue,
      light: gradients.colors.brightBlue,
      dark: gradients.colors.darkBlue,
    },
    secondary: {
      main: gradients.colors.brightBlue,
      light: gradients.colors.lightBlue,
      dark: gradients.colors.mediumBlue,
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#051C2C',
      secondary: '#0D2B3E',
    },
  },
  gradients,
  typography: {
    fontFamily: '"JetBrains Mono", "Roboto Mono", monospace',
    h1: {
      fontWeight: 700,
      letterSpacing: '-1px',
      fontSize: '2.25rem',
    },
    h2: {
      fontWeight: 600,
      letterSpacing: '-0.5px',
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 600,
      letterSpacing: '0px',
      fontSize: '1.75rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      // keep the same styles or omit entirely to keep the original size
    },
    body1: {
      fontSize: '1.1rem',
      lineHeight: 1.7,
      fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    },
    button: {
      fontFamily: '"JetBrains Mono", "Roboto Mono", monospace',
      fontWeight: 500,
    }
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
          borderRadius: 4,
          letterSpacing: '0.5px',
        },
        contained: {
          background: 'linear-gradient(45deg, #03111B 15%, #051C2C 30%, #2F5FFF 75%, #7B96FF 95%)',
          border: 'none',
          color: 'white',
          '&:hover': {
            background: 'linear-gradient(45deg, #7B96FF 15%, #2F5FFF 30%, #051C2C 75%, #03111B 95%)',
            border: 'none',
            opacity: 0.9
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid #eaeaea',
          borderRadius: 4,
          '&:hover': {
            border: '1px solid #2F5FFF',
          },
        },
      },
    },
  },
});

export default theme; 