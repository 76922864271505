export const gradients = {
  // Main gradients
  primary: 'linear-gradient(45deg, #03111B 15%, #051C2C 45%, #2F5FFF 95%)',
  primaryReversed: 'linear-gradient(45deg, #2F5FFF 15%, #051C2C 45%, #03111B 95%)',
  
  // Icon gradients (for smaller elements)
  iconGradient: 'linear-gradient(45deg, #03111B 30%, #2F5FFF 90%)',
  iconGradientReversed: 'linear-gradient(45deg, #2F5FFF 30%, #03111B 90%)',
  
  // Colors used in gradients (for reference and individual use)
  colors: {
    darkBlue: '#03111B',
    mediumBlue: '#051C2C',
    brightBlue: '#2F5FFF',
    lightBlue: '#7B96FF'
  }
}; 

