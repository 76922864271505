import React, { useState } from 'react';
import { 
  Container, Typography, Box, Grid, Card, 
  CardContent, TextField, Button, Paper, Link,
  Snackbar, Alert
} from "@mui/material";
import { motion } from "framer-motion";
import { 
  FaEnvelope, FaLinkedin, FaGithub, FaMapMarkerAlt, FaPhone
} from "react-icons/fa";
import emailjs from '@emailjs/browser';
import theme from "./theme";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        phone: formData.phone,
        message: formData.message,
        to_email: 'vladislav.levitin@vlimplement.com'
      };

      await emailjs.send(
        'service_oluvlqy',
        'template_kw0wany',
        templateParams,
        'cQIrKgXpZxeidLO-T'
      );

      setSnackbar({
        open: true,
        message: 'Message sent',
        severity: 'success'
      });

      // Clear form
      setFormData({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    } catch (error) {
      console.error('Error sending email:', error);
      setSnackbar({
        open: true,
        message: 'Failed to send message. Please try again.',
        severity: 'error'
      });
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const contactInfo = [
    {
      icon: <FaEnvelope />,
      title: "Email",
      content: "vladislav.levitin@vlimplement.com",
      link: "ms-outlook:compose?to=vladislav.levitin@vlimplement.com"
    },
    {
      icon: <FaPhone />,
      title: "Phone",
      content: "+47 909 22 042",
      link: "tel:+4790922042"
    },
    {
      icon: <FaLinkedin />,
      title: "LinkedIn",
      content: "Connect with us",
      link: "https://www.linkedin.com/in/vladislav-levitin-3ab860150/"
    },
    /* Commented out GitHub and Location
    {
      icon: <FaGithub />,
      title: "GitHub",
      content: "Follow our projects",
      link: "https://github.com/vladlevitin"
    },
    {
      icon: <FaMapMarkerAlt />,
      title: "Location",
      content: "Oslo, Norway",
      link: "https://maps.google.com/?q=Tollbugata+13+0152+Oslo"
    }
    */
  ];

  return (
    <Box
      sx={{
        py: { xs: 8, md: 12 },
        backgroundColor: 'background.default',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Container maxWidth="lg" sx={{ textAlign: 'center' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography 
            variant="h2" 
            align="center"
            sx={{ 
              mb: 3,
              fontWeight: 700,
              background: theme.gradients.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Get in Touch
          </Typography>
          <Typography 
            variant="body1" 
            align="center" 
            color="text.secondary"
            sx={{ 
              mb: 6,
              maxWidth: '600px', 
              mx: 'auto',
              fontSize: '1.1rem'
            }}
          >
            Get in touch to discuss how we can help with your next project
          </Typography>

          {/* Contact Info Cards */}
          <Grid
            container
            spacing={2}
            direction="column"
            sx={{
              mb: 8,
              maxWidth: 600,
              mx: 'auto',
              textAlign: 'center'
            }}
          >
            {/* Top row: 3 icons in horizontal axis */}
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 8
                }}
              >
                {/* Email Icon */}
                <Link
                  href="ms-outlook:compose?to=vladislav.levitin@vlimplement.com"
                  sx={{ textDecoration: 'none' }}
                >
                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: 2,
                      color: "white",
                      display: "inline-flex",
                      background: theme.gradients.iconGradient,
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        background: theme.gradients.iconGradientReversed,
                      }
                    }}
                  >
                    <FaEnvelope />
                  </Box>
                </Link>

                {/* Phone Icon */}
                <Link
                  href="tel:+4790922042"
                  sx={{ textDecoration: 'none' }}
                >
                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: 2,
                      color: "white",
                      display: "inline-flex",
                      background: theme.gradients.iconGradient,
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        background: theme.gradients.iconGradientReversed,
                      }
                    }}
                  >
                    <FaPhone />
                  </Box>
                </Link>

                {/* LinkedIn Icon */}
                <Link
                  href="https://www.linkedin.com/in/vladislav-levitin-3ab860150/"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                >
                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: 2,
                      color: "white",
                      display: "inline-flex",
                      background: theme.gradients.iconGradient,
                      cursor: 'pointer',
                      transition: 'transform 0.2s ease',
                      '&:hover': {
                        transform: 'scale(1.1)',
                        background: theme.gradients.iconGradientReversed,
                      }
                    }}
                  >
                    <FaLinkedin />
                  </Box>
                </Link>
              </Box>
            </Grid>

            {/* Second row: phone and email text */}
            <Grid item xs={12} sx={{ 
              textAlign: 'center',
              mt: 5,
              '& > :not(:last-child)': {
                mb: 2
              }
            }}>
              <Typography variant="body1" color="text.secondary">
                (+47) 909 22 042
              </Typography>
              <Typography variant="body1" color="text.secondary">
                vladislav.levitin@vlimplement.com
              </Typography>
            </Grid>
          </Grid>

          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <Paper
              elevation={0}
              sx={{
                maxWidth: 900,
                mx: 'auto',
                p: { xs: 3, md: 4 },
                textAlign: 'center',
                border: '1px solid #eaeaea',
                borderRadius: 1,
                mb: 4
              }}
            >
              <Typography 
                variant="h5" 
                sx={{ 
                  mb: 4,
                  fontWeight: 600
                }}
              >
                Send us a message
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid 
                  container 
                  spacing={3}
                  sx={{
                    maxWidth: 800,
                    mx: 'auto'
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Name"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      type="email"
                      value={formData.email}
                      onChange={handleChange}
                      variant="outlined"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Phone"
                      name="phone"
                      type="tel"
                      value={formData.phone}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="Tell us about your project or business needs"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      variant="outlined"
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      fullWidth
                      size="large"
                      sx={{
                        background: theme.gradients.primary,
                        border: 'none',
                        color: 'white',
                        py: 1.5,
                        mb: 2,
                        '&:hover': {
                          background: theme.gradients.primaryReversed,
                          border: 'none',
                          opacity: 0.9
                        }
                      }}
                    >
                      Send Message
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Paper>
          </motion.div>
        </motion.div>
      </Container>

      {/* Add Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
