import React, { useState } from "react";
import { FaLightbulb, FaChartLine, FaHandshake } from "react-icons/fa";
import { 
  Container, Typography, Box, Grid, Card, 
  CardContent, Avatar, Paper, Button, Chip
} from "@mui/material";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import profileImage from "./image/profile.jpg";
import theme from "./theme";

const About = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const experience = [
    {
      company: "Heimstaden Group",
      location: "Oslo, Norway",
      role: "Data Engineer",
      period: "Sep. 2024 - Present",
      description: [
        "Full-time Data Engineer in Heimstaden, Financial Controlling Group",
        "Responsible for automating financial and sustainability process reporting using Python, Microsoft Fabrics, and Microsoft Power Platform",
        "Creating dashboards for business intelligence and developing low-code solutions to address business challenges"
      ]
    },
    {
      company: "Deloitte",
      location: "Oslo, Norway",
      role: "AI & Data Consultant",
      period: "Jan. 2023 - Sep. 2024",
      description: [
        "Full-time consultant in the Artificial Intelligence & Data department at Deloitte",
        "Member of an internal data & insights team at the client, working on various projects",
        "Implemented API integrations and ETL pipelines in Azure Data Factory for data retrieval and analysis",
        "Contributed to data warehouse migration from on-premises to cloud using dbt and Snowflake",
        "Designed and implemented Power BI dashboards with embedded Power Apps for key insights",
        "Organized internal AI events such as AI Bootcamp and Techathon"
      ]
    },
    {
      company: "PwC",
      location: "Bergen, Norway",
      role: "Winter Intern, Data & Analytics",
      period: "Jan. 2022 - Feb. 2022",
      description: [
        "Developed a Python library using nbdev for retrieving and analyzing financial data from an API",
        "Created a GUI to accompany the Python library for users without programming experience",
        "Collaborated with team members to deliver solutions for the M&A team"
      ]
    },
    {
      company: "VitalThings",
      location: "Trondheim, Norway",
      role: "Developer Intern",
      period: "Jun. 2021 - Aug. 2021",
      description: [
        "Developed a fully functional app prototype using Flutter for a health care service",
        "Implemented Bluetooth connectivity for wearable sensor data collection",
        "Worked in a team environment to gather and analyze data from test users"
      ]
    }
  ];

  const skills = {
    "Programming Languages": ["Python", "Java", "DAX", "SQL", "CSS", "HTML"],
    "Development Tools": ["Git", "VS Code", "Azure", "Power Platform", "Snowflake", "dbt", "Databricks", "Terraform"],
    "Development Frameworks": ["TensorFlow", "scikit-learn", "dbt", "Next.js", "Flutter", "Django"],
    "Certifications": ["AZ-900", "AI-900", "DP-900", "AI-102", "DP-100"],
    "Languages": ["English (fluent)", "Norwegian (fluent)"]
  };

  const education = [
    {
      school: "Norwegian University of Science and Technology (NTNU)",
      degree: "MSc. Industrial Economics and Technology Management",
      period: "Aug. 2017 - Jun. 2023",
      location: "Trondheim, Norway",
      details: [
        "Technology profile: Computer science, specialization in artificial intelligence",
        "Main profile: Investment, finance and financial management",
        "GPA: 4.4/5.0"
      ]
    },
    {
      school: "Swiss Federal Institute of Technology Lausanne (EPFL)",
      degree: "Computer Science",
      period: "Sep. 2019 - Feb. 2020",
      location: "Lausanne, Switzerland",
      details: [
        "Exchange semester focusing on computer science fundamentals",
        "Enhanced international academic experience"
      ]
    }
  ];

  const extracurricular = {
    organization: "IBC (Indøk Business Club)",
    role: "Member",
    period: "Jan. 2019 - Dec. 2021",
    location: "Trondheim, Norway",
    description: "Active member of a case club focused on developing business skills through case solving. Participated in team-based case solutions and training programs led by experienced consultants."
  };

  const contactInfo = {
    location: "Tollbugata 13, 0152 Oslo",
    phone: "(+47) 909 22 042",
    email: "vladislav.levitin@vlimplement.com",
    linkedin: "vladislav-levitin-3ab860150",
    github: "vladlevitin"
  };

  const whyChooseUsData = [
    {
      icon: <FaLightbulb />,
      title: "Expertise",
      text: "Our team brings years of industry experience and cutting-edge knowledge to every project.",
    },
    {
      icon: <FaChartLine />,
      title: "Innovation",
      text: "We stay at the forefront of technological advancements to provide innovative solutions.",
    },
    {
      icon: <FaHandshake />,
      title: "Client-Centric Approach",
      text: "We focus on delivering measurable outcomes tailored to our clients' unique needs.",
    },
  ];

  return (
    <Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 3,
              fontWeight: 700,
              background: theme.gradients.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            About Us
          </Typography>
          <Typography 
            variant="body1" 
            align="center" 
            color="text.secondary"
            sx={{ 
              mb: 8, 
              maxWidth: '600px', 
              mx: 'auto',
              fontSize: '1.1rem'
            }}
          >
            Delivering innovative technology solutions with expertise and precision
          </Typography>

          {/* Why Choose Us Section */}
          <Grid container spacing={4} sx={{ mb: 8 }}>
          {whyChooseUsData.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
            <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card
                    sx={{
                      height: '100%',
                      transition: 'all 0.3s ease',
                      border: '1px solid',
                      borderColor: 'divider',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    <CardContent sx={{ p: 4, textAlign: 'center' }}>
                      <Box
                        sx={{
                          p: 1.5,
                          borderRadius: 2,
                          color: 'white',
                          display: 'inline-flex',
                          background: theme.gradients.iconGradient,
                          mb: 3
                        }}
            >
              {item.icon}
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{ 
                          mb: 2,
                          fontWeight: 600
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography color="text.secondary">
                        {item.text}
                      </Typography>
                    </CardContent>
                  </Card>
            </motion.div>
              </Grid>
            ))}
          </Grid>

          {/* Consultant Section */}
          <Box sx={{ textAlign: 'center', mt: 8 }}>
            <Typography 
              variant="h3" 
              sx={{ 
                mb: 6,
                fontWeight: 700,
                background: theme.gradients.primary,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              Consultant
            </Typography>
            
            {/* Flip Card Container */}
            <Box
              sx={{
                perspective: '1000px',
                maxWidth: '800px',
                minHeight: '600px',
                mx: 'auto',
                mb: 4,
                cursor: 'pointer'
              }}
              onClick={() => setIsFlipped(!isFlipped)}
            >
              {/* Flip Card Inner */}
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '600px',
                  transition: 'transform 0.8s',
                  transformStyle: 'preserve-3d',
                  transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
                }}
              >
                {/* Front Side */}
                <Paper
                  elevation={0}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backfaceVisibility: 'hidden',
                    p: 4,
                    textAlign: 'center',
                    border: '1px solid',
                    borderColor: 'divider',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      borderColor: 'primary.main',
                    },
                  }}
                >
                  <Avatar
              src={profileImage}
              alt="Vladislav Levitin"
                    sx={{
                      width: 200,
                      height: 200,
                      mx: 'auto',
                      mb: 3,
                      border: 3,
                      borderColor: 'primary.main'
                    }}
                  />
                  <Typography 
                    variant="h4" 
                    sx={{ 
                      mb: 2,
                      fontWeight: 600
                    }}
                  >
                    Vladislav Levitin
                  </Typography>
                  <Typography 
                    color="text.secondary"
                    sx={{ mb: 3 }}
                  >
                    Data Engineer at Heimstaden's Financial Controlling Group and former AI & Data Consultant at Deloitte. Graduate of NTNU with MSc. in Industrial Economics and Technology Management. Specializes in financial automation, cloud solutions, and business intelligence with expertise in Azure, Power Platform, Python, and modern data tools.
                  </Typography>
                  <Typography variant="body2" color="primary">
                    Click to view resume
                  </Typography>
                </Paper>

                {/* Back Side (Resume) */}
                <Paper
                  elevation={0}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    backfaceVisibility: 'hidden',
                    transform: 'rotateY(180deg)',
                    p: 4,
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Grid container spacing={3}>
                    {/* Left Column */}
                    <Grid item xs={6}>
                      {/* Experience */}
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2,
                          background: theme.gradients.primary,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 600
                        }}
                      >
                        Experience
                      </Typography>
                      {experience.slice(0, 2).map((exp, index) => (
                        <Box key={index} sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                            {exp.role}
                          </Typography>
                          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                            {exp.company} | {exp.period}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {exp.role === "Data Engineer" ? "Full-time Data Engineer in Heimstaden Group" : exp.description[0]}
                          </Typography>
                        </Box>
                      ))}

                      {/* Education */}
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2, 
                          mt: 3,
                          background: theme.gradients.primary,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 600
                        }}
                      >
                        Education
                      </Typography>
                      <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                        MSc. Industrial Economics and Technology Management
                      </Typography>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        NTNU | 2017 - 2023
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Specialization in Computer Science, AI and Finance
                      </Typography>
                    </Grid>

                    {/* Right Column */}
                    <Grid item xs={6}>
                      {/* Skills */}
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2,
                          background: theme.gradients.primary,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 600
                        }}
                      >
                        Skills
                      </Typography>
                      {Object.entries(skills).slice(0, 3).map(([category, items]) => (
                        <Box key={category} sx={{ mb: 2 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
                            {category}
                          </Typography>
                          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {items.slice(0, 4).map((item, i) => (
                              <Chip 
                                key={i} 
                                label={item} 
                                size="small"
                                sx={{
                                  backgroundColor: 'background.paper',
                                  border: '1px solid',
                                  borderColor: 'divider',
                                }}
                              />
                            ))}
                          </Box>
                        </Box>
                      ))}

                      {/* Certifications */}
                      <Typography 
                        variant="h6" 
                        sx={{ 
                          mb: 2,
                          mt: 3,
                          background: theme.gradients.primary,
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                          fontWeight: 600
                        }}
                      >
                        Certifications
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {skills.Certifications.map((cert, i) => (
                          <Chip 
                            key={i} 
                            label={cert} 
                            size="small"
                            sx={{
                              backgroundColor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                            }}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>

                  <Typography 
                    variant="body2" 
                    color="primary" 
                    sx={{ 
                      mt: 2, 
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: 16,
                      left: 0,
                      right: 0
                    }}
                  >
                    Click to flip back
                  </Typography>
                </Paper>
              </Box>
            </Box>
          </Box>
        </motion.div>
      </Container>
    </Box>
  );
};

export default About;
