import React, { useEffect, useRef, useState } from "react";
import {
  FaChartBar, FaDatabase, FaRobot, FaCloud, FaCode, FaTools, FaCogs
} from "react-icons/fa";
import { 
  Container, Typography, Box, Grid, Card, 
  CardContent, Chip, Button
} from "@mui/material";
import { motion } from "framer-motion";
import { useSearchParams, Link } from 'react-router-dom';
import theme from "./theme";

const Services = () => {
  const [searchParams] = useSearchParams();
  const highlightedService = searchParams.get('highlight');
  const highlightedRef = useRef(null);
  const [isHighlighted, setIsHighlighted] = useState(true);

  useEffect(() => {
    if (highlightedService && highlightedRef.current) {
      setIsHighlighted(true);
      highlightedRef.current.scrollIntoView({ 
        behavior: 'smooth', 
        block: 'center' 
      });
      const timer = setTimeout(() => {
        setIsHighlighted(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlightedService]);

  const services = [
    {
      title: "Web & Software Development",
      description: "Build modern web applications and custom software solutions using the latest technologies. We deliver scalable, user-friendly applications that drive business growth.",
      icon: <FaCode />,
      technologies: ["React", "Node.js", "Python", "Java", "Mobile Dev"],
      image: 'https://images.unsplash.com/photo-1461749280684-dccba630e2f6?auto=format&fit=crop&w=1200&h=600',
      color: "#FF5722"
    },
    {
      title: "Data Analytics",
      description: "Transform your data into actionable insights using Power BI, Tableau, and modern data warehouse solutions. We build interactive dashboards and reports that drive informed decision-making.",
      icon: <FaChartBar />,
      technologies: ["Python", "R", "Tableau", "Power BI", "Machine Learning"],
      image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&w=1200&h=600',
      color: "#0984E3",
      gradient: theme.gradients.tertiary
    },
    {
      title: "Data Warehouse Management",
      description: "Optimize your enterprise data pipeline with secure, scalable warehouse solutions.",
      icon: <FaDatabase />,
      technologies: ["SQL", "NoSQL", "Oracle", "MongoDB", "PostgreSQL"],
      image: 'https://images.unsplash.com/photo-1558494949-ef010cbdcc31?auto=format&fit=crop&w=1200&h=600',
      color: "#2D3436"
    },
    {
      title: "AI & Automation",
      description: "Combine AI tools like ChatGPT and Azure Cognitive Services with workflow automation (Power Automate, Zapier) to streamline operations and intelligence.",
      icon: <FaRobot />,
      technologies: ["ChatGPT", "Azure Cognitive Services", "Zapier", "Dialogflow"],
      image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&w=1200&h=600',
      color: "#9C27B0",
      gradient: theme.gradients.primary
    },
    {
      title: "Cloud Services",
      description: "Scale your infrastructure seamlessly with modern cloud solutions and DevOps practices.",
      icon: <FaCloud />,
      technologies: ["AWS", "Azure", "Docker", "Kubernetes"],
      image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&w=1200&h=600',
      color: "#03A9F4"
    },
    {
      title: "IT Consulting",
      description: "Strategic technology guidance to drive your digital transformation initiatives.",
      icon: <FaTools />,
      technologies: ["Digital Strategy", "Architecture", "Agile", "DevOps"],
      image: 'https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&w=1200&h=600',
      color: "#607D8B"
    },
    {
      title: "SEO & Marketing Optimization",
      description: "Boost online visibility and reach with targeted SEO strategies and automated marketing campaigns.",
      icon: <FaCogs />,
      technologies: ["SEO Tools", "Google Analytics", "HubSpot", "Social Media Ads"],
      image: 'https://images.unsplash.com/photo-1517292987719-0369a794ec0f?auto=format&fit=crop&w=1200&h=600',
      color: "#607D8B",
      gradient: theme.gradients.secondary
    }
  ];

  return (
    <Box sx={{ py: { xs: 8, md: 12 }, backgroundColor: 'background.default' }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography 
            variant="h2" 
            sx={{ 
              mb: 4,
              fontWeight: 700,
              background: theme.gradients.primary,
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent'
            }}
          >
            Our Services
          </Typography>
          <Typography 
            variant="body1" 
            align="center" 
            color="text.secondary"
            sx={{ 
              mb: 8, 
              maxWidth: '600px', 
              mx: 'auto',
              fontSize: '1.1rem'
            }}
          >
            Comprehensive technology solutions to drive your business forward
          </Typography>

          <Grid container spacing={4}>
            {services.map((service, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <Card
                    ref={service.title === highlightedService ? highlightedRef : null}
                    sx={{
                      height: '610px',
                      transition: 'all 0.5s ease',
                      position: 'relative',
                      transform: (service.title === highlightedService && isHighlighted) 
                        ? 'scale(1.02)' 
                        : 'none',
                      boxShadow: (service.title === highlightedService && isHighlighted)
                        ? '0 8px 24px rgba(0,0,0,0.12)' 
                        : 'none',
                      border: '1px solid',
                      borderColor: (service.title === highlightedService && isHighlighted)
                        ? 'primary.main' 
                        : 'divider',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        borderColor: 'primary.main',
                      },
                    }}
                  >
                    <CardContent sx={{ p: 4, pb: '80px' }}>
                      <Box
                        sx={{
                          width: '100%',
                          height: 140,
                          mb: 3,
                          borderRadius: 2,
                          overflow: 'hidden'
                        }}
                      >
                        <Box
                          component="img"
                          src={service.image}
                          sx={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            transition: 'transform 0.3s ease',
                            '&:hover': {
                              transform: 'scale(1.05)'
                            }
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 3,
                        }}
                      >
                        <Box
                          sx={{
                            p: 1.5,
                            borderRadius: 2,
                            color: 'white',
                            display: 'flex',
                            background: theme.gradients.iconGradient,
                          }}
                        >
                          {service.icon}
                        </Box>
                        <Typography
                          variant="h5"
                          sx={{
                            ml: 2,
                            fontWeight: 600,
                            background: theme.gradients.primary,
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent'
                          }}
                        >
                          {service.title}
                        </Typography>
                      </Box>

                      <Typography 
                        color="text.secondary" 
                        sx={{ mb: 3 }}
                      >
                        {service.description}
                      </Typography>

                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 3 }}>
                        {service.technologies.map((tech, i) => (
                          <Chip
                            key={i}
                            label={tech}
                            size="small"
                            sx={{
                              backgroundColor: 'background.paper',
                              border: '1px solid',
                              borderColor: 'divider',
                            }}
                          />
                        ))}
                      </Box>

                      <Button
                        component={Link}
                        to="/contact"
                        variant="outlined"
                        size="small"
                        sx={{
                          position: 'absolute',
                          bottom: '32px',
                          left: '32px',
                          background: theme.gradients.iconGradient,
                          border: 'none',
                          color: 'white',
                          '&:hover': {
                            background: theme.gradients.iconGradientReversed,
                            border: 'none',
                            opacity: 0.9
                          }
                        }}
                      >
                        Learn More
                      </Button>
                    </CardContent>
                  </Card>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </motion.div>
      </Container>
    </Box>
  );
};

export default Services;
